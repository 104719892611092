@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|Monda:700&display=swap');

html body .app.flex-row.align-items-center {
  height: 100vh;
}

body {
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-weight: 500;
}

.sidebar {
  background: #1A1A1A;
  border-right: 1px solid #2d31358f;
}

.sidebar .nav-link .nav-icon {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  color: #b8babc7d;
  text-align: center;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}

.sidebar .nav-link.active {
  border-radius: 12px;
  border: 3px solid #F39200;
  background: #3a414973;
  border-left: 5px solid #F39200 !important;
}

.app-header {
  background-color: #1A1A1A;
  border-bottom: 1px solid #2d31358f;
}

.sidebar .nav-link:hover {
  color: #fff;
  border-radius: 9px;
  background: #F39200;
}

a {
  color: #F39200;
  text-decoration: none;
  background-color: transparent;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #F39200;
  }
}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 200px;
  width: 65px;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.custom-control {
  min-height: 1.400rem;
  font-size: 13px;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #f36767;
  border-color: #ffa6a5;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ef3937;
  border-color: #f7a3a2;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f7a3a2;
  background-color: #F39200;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
  background-color: #333;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #575757;
  border-radius: 10px;
}

/* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* this will style the thumb, ignoring the track */

.breadcrumb {
  text-transform: uppercase;
  line-height: 15px;
  border-bottom: 1px solid #2d31358f;
  background-color: #1A1A1A;

}

.sidebar .nav-dropdown.open {
  background: #3c3b3b75;
}

.breadcrumb-item.active {
  color: #fff;
}

//LOGGIN
#styleContainer {
  max-width: 810px;
}

#card-body-loggin {
  min-height: auto;
}

.logo-Risk {
  color: white;
  font-weight: 600;
  font-size: 29px;
}

.card-body {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  min-height: 420px;
}

.contendor-background-padre {
  max-height: 480px;
  min-height: 420px;
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #1a1a1aab;
  color: #ffffff;
}

.contenido-logo-resk {
  align-content: center;
}

.contenedorBtn-Volver {
  text-align: right;
  width: 30%;
  margin-top: 5px;
}

.titulos {
  text-align: inherit;
}

.style-titulos {
  margin-top: 5px;
  font-weight: bold;
}

label {
  display: inline-block;
  width: 150px;
}

.input.disabled {
  opacity: .5;
  cursor: not-allowed;
}

#styleBtnLogin {
  min-width: 419px;
  color: white;
  background: #F39200;
}

#styleBtnOnlyLogin {
  width: 100%;
  color: white;
  background: #F39200;
}

#headerStyle-login {
  color: white;
  padding: 20px;
}

#headerStyle-login > a {
  color: white;
  font-size: 15px;
}

.sizeTable {
  font-size: 13px;
}

.buttonDefault {
  width: 100%;
  background-color: #F39200;
  border: #F39200;
  color: white;
}

.buttonDefault:hover {
  width: 100%;
  background-color: #d42422;
  color: white;
}

#style-btnAgregar {
  min-width: 30px;
}

.buttonDefault:active {
  width: 100%;
  background-color: #F39200 !important;
  color: white !important;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

//card
.card {
  margin-bottom: 3px;
  background-color: #333;
}

#cardInfoContrato {
  min-height: 482px;
}

.ColorCard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #333;
  background-clip: border-box;
  border: 1px solid #212121;
  border-radius: 0.25rem;
}

#styleButton {
  margin: 12px;
  width: 132px;
  border: solid 1px transparent;
  background: #F39200;
  color: #fff;
}

#eyeStyleButton {
  cursor: pointer;
  margin-left: 10px;
  font-size: 18px;
}

.contendor-background {
  background: #1a1a1aab;
  color: #ffffff;
  border-radius: 5px;
}

#contenedorInput {
  margin-bottom: 15px;
}

.TablaOverFlow {
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
}

.formularioStyle {
  margin-top: 15px;
}

.TablaOverFlow {
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
}

.tab-content {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: -1px;
  background: #1a1a1aab;
  border: none;
  color: #fff;
  height: auto;
  padding-bottom: 10px;
}



.nav-tabs {
  border-bottom: none;
  color: #fff;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  font-size: 12px;
}

.nav-tabs .nav-link {
  background: #F39200;
}

.nav-tabs .nav-link.active {
  color: #ffffff;
  background: #1a1a1aab;
  letter-spacing: 1px;
  border: none;
}

/*estilo de paginacion */
#pagin {
  float: right;
  margin-left: 15px;
}

#pagin > .pagination > li > a {
  color: #fff;
  padding: 6px;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #454f56;
  border-radius: 5px;
  background-color: #454f56;
}

#pagin > .pagination > .active > a {
  z-index: 3;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  background-color: #F39200;
  border-color: #F39200;
  cursor: default;
}

//datepicker

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid black;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__triangle {
  position: absolute;
  left: 117px !important;
}

.react-datepicker__input-container > input {
  background-color: #ffffff;
  border-color: #ffffffe6;
  text-align: center;
  color: black;
  width: 100%;
  margin-left: 0px !important;
  font-size: 11px;
  font-weight: bold;
  padding: .3rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: -48px !important;
  transform: translate3d(15px, -247px, 0px);
  margin-right: 22px;
}

//input file

chooseFile {
  display: flex;
  justifyContent: flex-start;
  alignItems: center;
  flexDirection: row;
  width: 20vw;
  padding: 5px;
  borderStyle: solid;
  borderWidth: 1;
  borderColor: green;
  borderRadius: 5;
  color: green;
}

#ButtonStyleModificar {
  background: #F39200;
  color: #ffff;
  border: none;
  margin-right: 3px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
  }
}

.modal-content {
  background-color: #454f56;

}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border-radius: 4px;
    border: 1px solid #6b6b6b;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    max-width: 91px;
    background: rgba(156,154,152,.2196078431372549);
}
